<template>
  <v-app>
    <v-parallax
      :lazy-src="img(src, { quality: 70 })"
      :src="img(src, { quality: 70 })"
      height="50vh"
      :sizes="_srcset.sizes"
      gradient="to top right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)"
      alt="MineClub"
    >
      <TheHeader />
      <v-main>
        <v-container fluid>
          <v-row justify="center">
            <v-col cols="12" md="8">
              <v-card class="card">
                <NuxtPage />
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-main>
    </v-parallax>
  </v-app>
</template>

<script setup lang="ts">
const props = defineProps({
  height: { type: [Number, String], default: 500 },
  src: {
    type: String,
    default: '/img/parallaxfor.png',
  },
});
const img = useImage();
const _srcset = computed(() => {
  return img.getSizes(props.src, {
    sizes: 'xs:100vw sm:100vw md:100vw lg:100vw xl:100vw',
    modifiers: {
      format: 'webp',
      quality: 70,
      height: Number(props.height),
    },
  });
});
</script>

<style scoped>
.card {
  padding: 20px;
  height: 90vh;
  overflow-y: auto; /* Enable vertical scrolling when content exceeds viewport height */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-top-left-radius: 2%;
  border-top-right-radius: 2%;
}
</style>
